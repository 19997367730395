<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <hero-component
        background-url="/img/renters-2022/hero-section.jpg"
        btn-text="Get started on your renters policy"
        btn-link="/renters/infoca"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        align="flex-start"
        gap="20px"
        class="hero-custom-style"
      >
        We've got your<br>contents,<br>covered.
      </hero-component>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your prized possessions with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Don’t let damage or loss leave you high and dry.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-cash-multiple
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-application-cog-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-handshake-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="10"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Insure your possessions for only a few dollars a week
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Customized coverage for protecting the unexpectedness in life
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support when you need it
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Have questions?
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex flex-column justify-center s2-desc">
              Protect small electronics, clothing, sporting equipment, and other prized personal possessions at your place—for less.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              For covering items unique to your lifestyle, talk to us if you need to explore more customized options.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our technology and customer support will be there (even if your landlord isn’t) to file a claim, ask a question, or make changes to your policy.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Check out our
              <a
                class="link-style primary--text text-decoration-underline"
                @click="goToFaqs('desktop-faq')"
              >
                FAQs
              </a>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/renters/infoca"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your peace of mind, covered.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Unless specifically excluded in your policy, all Blanket Renters policies include the following coverage:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in rentersNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Don’t get caught without protection for you and your belongings. With contents limits available from as low as $30,000, $2 million personal liability, and coverage for sewer back-up automatically included; you can rest easy.
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Renters coverage in less time than it takes to microwave a burrito.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="style-btn">
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/renters/infoca"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your renters policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          id="desktop-faq"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-desk"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <hero-component
        background-url="/img/renters-2022/tablet-hero-img.jpg"
        btn-text="Get started on your renters policy"
        btn-link="/renters/infoca"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        align="flex-start"
        gap="20px"
        class="hero-custom-style"
      >
        We've got your<br>contents,<br>covered.
      </hero-component>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your prized possessions with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Don’t let damage or loss leave you high and dry.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-cash-multiple
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-application-cog-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-handshake-outline
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Insure your possessions for only a few dollars a week
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Customized coverage for protecting the unexpectedness in life
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support when you need it
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Have questions?
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center s2-desc">
              Protect small electronics, clothing, sporting equipment, and other prized personal possessions at your place—for less.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              For covering items unique to your lifestyle, talk to us if you need to explore more customized options.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our technology and customer support will be there (even if your landlord isn’t) to file a claim, ask a question, or make changes to your policy.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Check out our
              <a
                href="#"
                class="link-style"
                @click="goToFaqs('tablet-faq')"
              >
                FAQs
              </a>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/renters/infoca"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your peace of mind, covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Unless specifically excluded in your policy, all Blanket Renters policies include coverage for these perils:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in rentersNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Depending on where you live, you may have the option to further customize your policy by adding coverage for certain excluded perils like earthquake damage and pet liability. In addition, you may also add endorsements for coverage not normally included in the policy such as cyber protection or identity protection.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in rentersNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Renters coverage in less time than it takes to microwave a burrito.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/renters/infoca"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your renters policy today</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          id="tablet-faq"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-tablet"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      ></div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5">
              We've got your<br> contents,<br> covered.
            </v-col>
          </v-row>
          <v-btn
            x-large
            class="mint mx-auto py-8"
            to="/renters/infoca"
          >
            <span class="section2-btn-txt-mobile pt-1">get started on <br>your renters policy</span>
          </v-btn>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            Protect your prized possessions with Blanket
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="section3-sub-mobile">
            Don’t let damage or loss leave you high and dry.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-cash-multiple
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Insure your possessions for only a few dollars a week
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Protect small electronics, clothing, sporting equipment, and other prized personal possessions at your place—for less.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-application-cog-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            <!-- 10-Day Free Look Period -->
            Customized coverage for protecting the unexpectedness in life
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            For covering items unique to your lifestyle, talk to us if you need to explore more customized options.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-handshake-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Support when you need it
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Our technology and customer support will be there (even if your landlord isn’t) to file a claim, ask a question, or make changes to your policy.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-help-box
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Have questions?
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Check out our
            <a
              class="link-style primary--text text-decoration-underline"
              @click="goToFaqs('mobile-faq')"
            >
              FAQs
            </a>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0"
              to="/renters/infoca"
            >
              apply now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            Your peace of mind, covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Unless specifically excluded in your policy, all Blanket Renters policies include coverage for these perils:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in rentersNeeds"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Depending on where you live, you may have the option to further customize your policy by adding coverage for certain excluded perils like earthquake damage and pet liability. In addition, you may also add endorsements for coverage not normally included in the policy such as cyber protection or identity protection.
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            Get Renters coverage in less time than it takes to microwave a burrito.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              to="/renters/infoca"
            >
              <span class="section2-btn-txt-mobile pt-1">get your renters <br> policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          id="mobile-faq"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-mobile"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'
  export default {
    name: 'RentersCa',
    components: {
      HeroComponent
    },

    data () {
      return {
        covid19Faq: false,
        covFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        rentersNeeds: [
          'Losses caused by fire, lightning, windstorm, hail, volcanic eruption, riot or civil commotion, aircraft, vehicles, smoke, vandalism or malicious mischief, theft, falling objects, weight of ice, snow or sleet, accidental discharge or water or stream, freezing and sudden accidental damage from electrical current. All are subject to your selected deductible.',
          'Replacement Cost.',
          'Sewer Back-Up.',
          '$2 Million Personal Liability.',
          '$30,000 minimum contents limit, up to $120,000 contents limit available.'
        ],
        mainFaqList: [
          {
            status: true,
            title: 'FAQs',
            list: [
              {
                ques: 'What is tenant insurance?',
                ans: 'As a renter, the insurance you buy inorder to protect your personal belongings if they are damaged or stolen and for liability if someone should have an accident while in your place, is called Tenant Insurance.'
              },
              {
                ques: 'Why do I need it?',
                ans: 'To protect yourself and your family in the event of an accident.'
              },
              {
                ques: 'What does contents amount mean?',
                ans: 'It is the value of all your personal belongings, eg. bicyles, computers, art, watches etc.'
              },
              {
                ques: 'What does liability amount mean?',
                ans: 'If anyone has an accident while a guest you can be sued, eg. A friend visiting or a delivery person etc.'
              },
              {
                ques: 'Is this my primary residence?',
                ans: 'Yes, if you have signed the lease to live here, this is your primary residence.'
              },
              {
                ques: 'How many roommates can I live with (non-family members)?',
                ans: 'A maximum of 2 roomates, who are non-family members is allowed.'
              },
              {
                ques: 'What is a claim?',
                ans: 'If your personal belongings have been stolen or damaged or someone inside your residence has an accident and wants to sue you, you would make a claim.'
              },
              {
                ques: 'What if I made a claim in the last five years?',
                ans: 'You will not be able to complete the application online, an agent will contact you.'
              },
              {
                ques: 'Do I need a computer to fill out the application?',
                ans: 'It is easier on a computer but the application is available on mobiles.'
              },
              {
                ques: 'What does deductible mean?',
                ans: 'If you make a claim for damages or theft of 14k, it is the minimum amount you will pay, eg. $500 or $1000 of the 14k lost or damaged.'
              },
              {
                ques: 'Can I purchase 15k in contents and 2m in liability?',
                ans: 'No, 15k is preset for 1m, only available above 30k contents.'
              },
              {
                ques: 'What do I do if I need to make a claim?',
                ans: 'Contact the following email address: <a href="mailto:customerservice@blanket.com" target="_blank"> customerservice@blanket.com </a>'
              },
              {
                ques: 'What do I do if I need to cancel my policy?',
                ans: 'Contact the following email address: <a href="mailto:customerservice@blanket.com" target="_blank"> customerservice@blanket.com </a>'
              },
              {
                ques: 'What do I do if I move out?',
                ans: 'Contact the following email address: <a href="mailto:customerservice@blanket.com" target="_blank"> customerservice@blanket.com </a>'
              },
              {
                ques: 'Can I pay with interac?',
                ans: 'No, just credit cards.'
              },
              {
                ques: 'What happens if I miss a payment?',
                ans: 'Contact the following email address: <a href="mailto:customerservice@blanket.com" target="_blank"> customerservice@blanket.com </a>'
              },
              {
                ques: 'Do you sell blankets?',
                ans: 'Only metaphoricaly, we are an insurtech company.'
              },
            ],
          },
        ],
      }
    },
    computed: {
      xlDevice () {
        return null
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
    },
    created () {
      this.$gtag.pageview('Renters USA')
      this.currentuser = this.$store.getters.getuser
      this.$store.dispatch('getPropertyTypes')
      this.$store.dispatch('clearError')
    },

    methods: {
      toggleFaq (index) {
        this.mainFaqList[index].status = !this.mainFaqList[index].status
      },
      toggleCovid19Faq () {
        this.covid19Faq = !this.covid19Faq
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      goToFaqs (sectionId) {
        const targetSection = document.getElementById(sectionId)
        if (targetSection) {
          console.log('targetSection', targetSection)
          targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.s2-icon {
  font-size: 50px !important;
}

.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */

text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;

/* Color Palette/Dark Grey */

color: #292F36;
}

.s2-desc {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
.s2-subhead {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 24px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }

  .cta-btn {
    position: absolute;
    top: 78%;
    left: 9%;
  }

  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }
  .sora {
position: absolute;
top: 45%;
left: 9%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
  }

  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}
.section1-desktop{
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/renters-2022/hero-section.jpg");
  background-size: cover;
}

.section1-xl{
  position: relative;
  width: 100vw;
  height: 750px;
  background: url("/img/renters-2022/hero-section.jpg");
  background-size: cover;
}
.section2 {
position: relative;
/* background-color: #FFFFFF; */
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}
.spacer {
  height: 6rem;
  z-index: 2;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.section3 {
  position: relative;
  height: 700px;
  background-color: #005F6C;
}

.review-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.review-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.review-details {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #FFFFFF;
}

.review-sig {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.review-ins {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.portrait-card {
position: absolute;
width: 615px;
height: 700px;
left: 880px;
top: -30px;
background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0) ), url('/img/pet-review-portrait.png');
border-radius: 10px;
}

.portrait-quote {
  width: 300px;
height: 364px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 72px;
line-height: 100%;
/* or 72px */

letter-spacing: -0.636172px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.portrait-text-container {
  margin-top:100px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 90px 20px 30px;
width: 100%;
height: 251px;
background: #00A1B7;
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
z-index: 3;
border-radius: 0 0 0 10px;
}

.portrait-text {
  font-family: 'Poppins';
font-style: italic;
font-weight: 500;
font-size: 17.9695px;
line-height: 125%;
letter-spacing: -0.202611px;
color: #FFFFFF;
}

.signature-container{
  position: absolute;
  right: 10rem;
  top: 42rem;
}

.section4 {
background: #FFFFFF;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 163px;
}

.sec4-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* Color Palette/Dark Grey */

color: #292F36;
}

.sec4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.covg-cta-txt {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* midnight eagle green */

color: #005F6C;
}

.learn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* gunmetal */

color: #292F36;
}

.sec5-dsk {
position: relative;
height: 438px;
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
padding-left: 10%;
padding-right: 10%;
}

.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
@media screen and (max-width: 1275px) {
  .sec5-dsk-phone {
    left: 640px;
  }
}
@media screen and (max-width: 1175px) {
  .sec5-dsk-phone {
    display: none;
  }
}

.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}

.sec5-title {
  position: absolute;
  left: 130px;
  top: 76px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */

letter-spacing: -0.424019px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 125%;
width: 598px;
height: 120px;
/* or 40px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}

.faq-sec-desk {
  background: #FFFFFF;
  padding-left: 1%;
  padding-right: 1%;
}

.faq-header-desk {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-panel-icon {
  color: #005F6C;
}

.cov-faq-title:hover {
  cursor: pointer;
}

.poppins-300 {
  font-family: 'Poppins';
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url('/img/renters-2022/mobile-hero-img.jpg');
  background-position: center;
  background-size: cover;
  height: 375px;
}

.section2-mobile {
width: 100vw;
height: 260px;
background: #00A1B7;
}

.section2-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;

}

.section2-btn-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 125%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #292F36;
}

.section3-mobile {
  padding-bottom: 54px;
  background-image: url('/img/knit-mobile.png');
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.section3-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #292F36;
}

.section3-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
}

.covg-txt-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}

.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
}

.s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
text-align: left;
}

.panel-title-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
display: flex;
align-items: center;
color: #005F6C;
}

.panel-icon {
  color: #292F36;
  font-size: 20px !important;
}

::v-deep .v-expansion-panel-header--active .v-icon {
  color: #FFFFFF !important;
}

.s5-mobile {
  position: relative;
  min-height: 725px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url('/img/partner-background-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.s5-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s5-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
}

.faq-header-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section1-tablet {
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/renters-2022/tablet-hero-img.jpg");
  background-position: center;
  background-size: cover;
}

.section2-tablet {
  position: relative;
/* background-color: #FFFFFF; */

background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}

.faq-header-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.sora-tablet {
  position: absolute;
top: 45%;
left: 5%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
}

.cta-btn-tablet {
    position: absolute;
    top: 78%;
    left: 5%;
  }

  ::v-deep .v-expansion-panel-header {
    color:#00A1B7;
  }

  ::v-deep .v-expansion-panel--active .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }
  .link-style{
    margin-left: 5px;
  }
  .style-btn{
    position: absolute;
    left: 0px;
  }
  .hero-custom-style{
      padding-block: 8.5%;
  }
</style>
